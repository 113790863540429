import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode"
import { Ability, AbilityBuilder } from '@casl/ability'

export const isUserLoggedIn = () => {
  return !!Cookies.get('wetu_id_token')
}

export const getUserData = () => {
  const cookie = Cookies.get('wetu_id_token')

  if (cookie) {
    return jwt_decode(cookie)
  }

  return {}
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}


export const canViewMenuGroup = (item, ability) => {
  const hasAnyVisibleChild = item.children && item.children.some(i => ability.can(i.action, i.resource))
  if (!(item.action && item.resource)) {
    return hasAnyVisibleChild
  }

  return ability.can(item.action, item.resource) && hasAnyVisibleChild
}

export const canViewMenuItem = (item, ability) => {
  return ability.can(item.action, item.resource)
}

export const createUserAbilities = () => {
  const userData = getUserData()

  userpilot.identify(userData.UserId, {
    accountPlan: userData.AccountPlan
  })

  if (typeof userData === 'object' && Object.keys(userData).length === 0) {
    return
  }

  const accountOptions = JSON.parse(userData?.AccountOptions)
  const { can, build, cannot } = new AbilityBuilder(Ability)

  //Wetu Superusers
  if (userData.IsWetu && userData.UserRole === "Admin") {
    can("manage", "all")

    //this are only for clients and unncessary for Wetu users
    cannot("read", "account")

    return build()
  } else {
    // All users
    can("read", ["user", "dashboard", "personal-details", "status-page", "logout", "profiles", "specials-portal", "ibrochure-search"])

    if (userData.UserRole === "Admin") {
      can("read", "account")
    }

    //Option based
    const allowedAccountTypes = ["Operator", "DMO", "Product", "Marketer", "Micro Operator", "TravelAgent"]

    if (allowedAccountTypes.includes(userData.AccountType)) {

      const { DigitalItinerary, VirtualItineraries, IsIntegrationClient, DayTours, Analytics, SpecialsManager, ConsultantPortal } = accountOptions

      if ((userData.AccountType !== "DMO" && userData.AccountType !== "Product" && userData.AccountType !== "Marketer" && userData.AccountPlan !== "Day Tour") && (DigitalItinerary === "True" || VirtualItineraries === "True")) {
        can("read", "calendar")
      }

      if (userData.AccountPlan === "Day Tour") {
        can("read", ["components", "daytours", "multidaytours"])
      }

      if (["Trial", "Virtual Enterprise", "Digital Enterprise", "Day Tour", "Marketer", "TravelAgent"].includes(userData.AccountPlan) || (userData.AccountType === "Product" && userData.AccountPlan === "Gold")) {
        can("read", "catalogue-manager")
      }

      if ((userData.AccountType === "DMO" || userData.AccountType === "Marketer") && VirtualItineraries === "True") {
        can("read", ["itineraries", "components"])
      }

      if ((DigitalItinerary === "True" || VirtualItineraries === "True") && (["Trial", "Free", "Virtual Lite", "Virtual Enterprise", "Digital Lite", "Digital Enterprise"].includes(userData.AccountPlan))) {     
        if (userData.AccountType !== 'Product') {
          can("read", ["itineraries", "components"])
        }
      }

      if (IsIntegrationClient === "True" && !["Trial", "Virtual Enterprise", "Digital Lite"].includes(userData.AccountPlan) && userData.AccountType !== "DMO") {
        can("read", ["connect", "connect-bookings"])
      }

      if ((DayTours === "Public" || DayTours === "Enabled")) {
        can("read", ["published-daytours", "daytours", "published-multidaytours", "multidaytours"])
      }

      if (DayTours === "Enabled" && (DigitalItinerary === "True" || VirtualItineraries === "True") && userData.AccountPlan !== "Digital Lite") {
        can("read", ["daytours", "multidaytours"])
      }

      if (Analytics === "True" && userData.AccountType !== "Micro Operator") {
        can("read", "analytics")
      }

      if (SpecialsManager === "True") {
        can("read", "specials-manager")
      }

      if (ConsultantPortal === "True" && (["Operator", "TravelAgent"].includes(userData.AccountType) || userData.AccountPlan === "Day Tour")) {
        can("read", "consultant-portal")
      }
    }

    return build()
  }
}
